import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { Flex } from 'rebass';

import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip/Tooltip';
import useInstitut from '@/hooks/useInstitut';
import { formatEuro } from '@/utils';

const FinancingTooltip = (purchasePrice) => {
  const { data: institut } = useInstitut();
  const { t } = useTranslation('page-indice');
  const d = t('tooltipp', {}, { returnObjects: true });
  return (
    <>
      <Tooltip>
        <Text variant="tooltipTitle">{d.title}</Text>
        <Flex
          justifyContent={['space-between', 'space-between', ' space-between']}
          mb={['0.438rem', '0.438rem', '0.438rem']}
        >
          <Text variant="tooltipText">{d.kaufpreis}</Text>
          <Text variant="tooltipText">{formatEuro(purchasePrice?.purchasePrice)}</Text>
        </Flex>
        <Flex
          justifyContent={['space-between', 'space-between', ' space-between']}
          mb={['0.438rem', '0.438rem', '0.438rem']}
        >
          <Text variant="tooltipText">
            - {d.eigenkapital} ({purchasePrice?.equityCapitalRatio} %)
          </Text>
          <Text variant="tooltipText">{formatEuro(purchasePrice?.equityCapital)}</Text>
        </Flex>
        <Flex
          justifyContent={['space-between', 'space-between', ' space-between']}
          mb={['0.438rem', '0.438rem', '0.438rem']}
        >
          <Text variant="tooltipText">
            + {d.kaufnebenkosten} ({purchasePrice?.incidentalPurchaseCostsRatio} %)
          </Text>
          <Text variant="tooltipText">{formatEuro(purchasePrice?.incidentalPurchaseCosts)}</Text>
        </Flex>
        <Flex
          justifyContent={['space-between', 'space-between', ' space-between']}
          mb={['0.438rem', '0.438rem', '0.438rem']}
        >
          <Text variant="tooltipText">= {d.nettodarlehen}</Text>
          <Text variant="tooltipText">{formatEuro(purchasePrice?.loanAmount)}</Text>
        </Flex>
        <Flex
          justifyContent={['space-between', 'space-between', ' space-between']}
          mb={['0.563rem', '0.563rem', '0.563rem']}
        >
          <Text variant="tooltipText" fontWeight={['700', '700', '700']}>
            {d.finanzierungAbCa}
          </Text>
          <Text variant="tooltipText" fontWeight={['700', '700', '700']}>
            {formatEuro(purchasePrice?.monthlyRate)}
          </Text>
        </Flex>
        <Flex>
          <Text variant="tooltipText">
            {d.text
              .replace('{initialRepayment}', purchasePrice?.initialRepayment)
              .replace('{boundInterestRate}', purchasePrice?.boundInterestRate)
              .replace('{effektivZins}', purchasePrice?.effektivZins)
              .replace('{zinsBindungInJahren}', purchasePrice?.zinsBindungInJahren)
              .replaceAll('{laufzeitInMonaten}', purchasePrice?.laufzeitInMonaten)
              .replace('{spk}', institut)
              .replace('{summeZahlungen}', formatEuro(purchasePrice?.summeZahlungen))}
          </Text>
        </Flex>
      </Tooltip>
    </>
  );
};

export default FinancingTooltip;
