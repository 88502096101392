import { AnimatePresence, motion } from 'framer-motion';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import { ArrowLightboxIcon } from '../Icon';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 400 : -400,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 400 : -400,
      opacity: 0,
    };
  },
};

const ArrowButton = ({ onClick, position, hide, isLayout }) => {
  const size = isLayout ? 35 : 25;

  return (
    <Flex
      as="button"
      type="button"
      onClick={onClick}
      sx={{
        all: 'unset',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 11,
        borderRadius: '100px',
        width: `${size}px`,
        height: `${size}px`,
        border: 'solid 1px',
        borderColor: 'border',
        bg: isLayout ? 'backgroundGrey' : 'white',
        left: position === 'left' ? '15px' : '',
        right: position === 'left' ? '' : '15px',
        transform: position === 'left' ? 'scale(-1)' : '',
        boxSizing: 'border-box',
        opacity: hide ? '0 !important' : '1 !important',
        transition: 'opacity, visibility 200ms ease',
        visibility: hide ? 'hidden' : 'visible',
        pointerEvents: 'auto',
        ':hover': {
          bg: 'lightBorder',
        },
        ':active': {
          bg: 'darkBorder',
        },
      }}
    >
      <ArrowLightboxIcon width={isLayout ? size - 26 : 4.709} height={isLayout ? size - 20 : 8.409} />
    </Flex>
  );
};

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  position: PropTypes.string,
  isLayout: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
};

ArrowButton.defaultProps = {
  position: 'right',
};

const PaginationRow = ({ paginate, isLayout, hasNext, hasPrev }) => {
  return (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        paddingY: '11px',
        paddingX: '15px',
        pointerEvents: 'none',
        justifyContent: isLayout ? 'center' : 'center',
      }}
    >
      <Flex
        sx={{
          pointerEvents: 'none',
          width: '100%',
          justifyContent: 'space-between',
          zIndex: 11,
          transition: 'opacity 200ms ease-in',
          opacity: isLayout ? 1 : 1,
          '.indicatorShower:hover &': {
            opacity: 1,
          },
        }}
      >
        <ArrowButton position="left" onClick={() => paginate(-1)} hide={!hasPrev} isLayout={isLayout} />
        <ArrowButton position="right" onClick={() => paginate(1)} hide={!hasNext} isLayout={isLayout} />
      </Flex>
    </Flex>
  );
};

PaginationRow.propTypes = {
  paginate: PropTypes.func.isRequired,
  isLayout: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
  hasPrev: PropTypes.bool.isRequired,
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const ImageGallery = ({ images, isDragging, mode, onPaginationChange }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const isLayout = mode === 'layout';

  const hasNext = page + 1 < images.length;
  const hasPrev = page - 1 >= 0;

  const paginate = (newDirection) => {
    const nextPage = page + newDirection;
    if (nextPage >= 0 && nextPage < images.length) {
      setPage([nextPage, newDirection]);
      onPaginationChange?.(nextPage);
    }
  };

  const MyNextImage = styled(NextImage)`@media (min-width: 1024px) {
    .pic-wrap {
      width: 140px;
    }`;

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={images[page].src}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
          custom={direction}
          variants={variants}
          initial="enter"
          onMouseEnter={() => {
            isDragging?.set?.(1);
          }}
          onMouseLeave={() => {
            isDragging?.set?.(0);
          }}
          animate="center"
          exit="exit"
          transition={{
            duration: 0.5,
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <div style={{ pointerEvents: 'none', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <Box sx={{ position: 'relative', width: '100%', height: isLayout ? '90%' : '100%' }}>
              <MyNextImage
                src={images[page].src}
                alt={images[page].alt}
                layout="fill"
                priority
                objectFit={mode === 'default' ? 'cover' : 'contain'}
                quality="50"
                width={8}
                sizes={8}
              />
            </Box>
            {isLayout ? <Box sx={{ height: '10%' }} /> : null}
          </div>
        </motion.div>
      </AnimatePresence>
      <PaginationRow
        paginate={paginate}
        position={mode === 'default' ? 'bottom' : 'center'}
        isLayout={isLayout}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      {/*
      <SliderIndicator index={page} length={images.length} variants={mode === 'default' ? undefined : redVariant} />
       */}
    </>
  );
};

ImageGallery.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isDragging: PropTypes.object,
  mode: PropTypes.oneOf(['default', 'layout']),
  onPaginationChange: PropTypes.func,
};

ImageGallery.defaultProps = {
  isDragging: null,
  mode: 'default',
  onPaginationChange: undefined,
};

export default ImageGallery;
