import { useWindowWidth } from '@react-hook/window-size';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useLayoutEffect, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import Divider from '@/components/Divider';
import { ArrowIcon, DeletedObjectIcon, LocationIcon, NoImageIcon } from '@/components/Icon';
import ImageGallery from '@/components/ImageGallery';
import Text from '@/components/Text';
import { formatDecimal, formatEuro } from '@/utils';

import FinancingTooltip from './Tolltip';

const ActionIconPositioner = ({ children }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    m={['15px', '15px', '15px']}
    sx={{
      background: 'rgba(255,255,255,.8)',
      borderRadius: '50%',
      position: 'absolute',
      right: '0px',
      top: '0px',
      height: '26px',
      width: '26px',
      zIndex: 1,
    }}
  >
    {children}
  </Flex>
);

ActionIconPositioner.propTypes = {
  children: PropTypes.node.isRequired,
};

const NoImages = ({ text, children }) => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bg: 'darkGrey',
      }}
    >
      {children}
      <Text variant="estateCardText" textAlign="center" color="disabled" mt={['20px', '20px', '20px']}>
        {text}
      </Text>
    </Flex>
  );
};

NoImages.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

const CardHead = ({ isDragging, images, deleted, deactivated }) => {
  const { t } = useTranslation('common-estate-card');
  const states = t('states', {}, { returnObjects: true });

  if (deleted)
    return (
      <NoImages text={states?.deleted?.title ?? 'Das Objekt wurde gelöscht'}>
        <DeletedObjectIcon />
      </NoImages>
    );

  if (deactivated) return <NoImages text={states?.deactivated ?? 'Das Objekt wurde deaktiviert'} />;

  if (!images?.length)
    return (
      <NoImages text={states?.noImages ?? 'Keine Bilder Vorhanden'}>
        <NoImageIcon />
      </NoImages>
    );

  return <ImageGallery isDragging={isDragging} images={images} />;
};

CardHead.propTypes = {
  deactivated: PropTypes.bool.isRequired,
  deleted: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isDragging: PropTypes.object,
};
CardHead.defaultProps = {
  isDragging: null,
};

const Card = ({
  children,
  images,
  title,
  roomCount,
  livingSpace,
  town,
  purchasePrice,
  deleted,
  deactivated,
  isDragging,
  id,
  isCompact,
  isRentalEstate,
  rentalEstate,
  monthlyRate,
  loanAmount,
  equityCapital,
  boundInterestRate,
  effectiveInterestRate,
  fixedInterestRate,
  incidentalPurchaseCosts,
  incidentalPurchaseCostsRatio,
  initialRepayment,
  zinsBindungInJahren,
  produktAnbieter,
  equityCapitalRatio,
  summeZahlungen,
  laufzeitInMonaten,
  effektivZins,
  ...props
}) => {
  const { t } = useTranslation('common-estate-card');
  const states = t('states', {}, { returnObjects: true });
  const objectData = t('objectData', {}, { returnObjects: true });
  const [imageheight, setImageHeight] = useState(240);

  const setHeight = () => {
    const imageWidth = document.getElementById('imageWrapper').offsetWidth;
    const newImageHeight = (imageWidth / 4) * 3;
    setImageHeight(newImageHeight);
  };
  const width = useWindowWidth();
  useLayoutEffect(() => {
    setHeight();
  }, [width]);
  return (
    <Flex
      className="indicatorShower"
      id="imageWrapper"
      onLoad={() => setHeight()}
      sx={{
        position: 'relative',
        flexDirection: 'column',
        // minWidth: ['320px', '320px', '320px'],
        flexBasis: isCompact ? '250px' : '320px',
        bg: 'white',
        borderRadius: isCompact ? '6px' : '10px',
        border: isCompact ? 'solid 1px #ebebeb' : '',
        boxShadow: isCompact ? '' : '1px 1px 5px 0 rgba(0, 0, 0, 0.16)',
        overflow: 'hidden',
        transition: 'all 300ms ease',
        ':hover': {
          boxShadow: isCompact ? '' : '1px 1px 20px 0 #00000029',
        },
        'scroll-snap-align': 'start',
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          position: 'relative',
          height: isCompact ? '125px' : imageheight,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardHead isDragging={isDragging} images={images} deleted={deleted} deactivated={deactivated} />
        {children ? <ActionIconPositioner>{children}</ActionIconPositioner> : null}
      </Flex>

      <Box
        p={[6, 6, 6]}
        sx={{
          minHeight: isCompact ? '175px' : '215px',
          filter: deactivated ? 'opacity(50%) grayscale(100%)' : '',
        }}
      >
        {deleted ? (
          <Flex
            sx={{
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text variant="estateCardText" textAlign="center">
              {states?.deleted.text}
            </Text>
          </Flex>
        ) : (
          <>
            <Link href={`/immobilien/immobiliensuche/expose/${id}`}>
              <a title={`${title} in ${town}`} aria-labelledby={id}>
                <Box>
                  <Text
                    variant={isCompact ? 'smallBoldEstate' : 'bodyBoldEstate'}
                    sx={{
                      display: '-webkit-box',
                      textOverflow: 'ellipsis',
                      '-webkit-line-clamp': '2',
                      '-webkit-box-orient': 'vertical',
                      overflow: 'hidden',
                      height: isCompact ? '2.25rem' : '3.125rem',
                    }}
                  >
                    {title}
                  </Text>
                  <Text
                    fontSize={['0.75rem', '0.75rem', '0.75rem']}
                    mt={isCompact ? ['0.625rem', '0.625rem', '0.625rem'] : ['1.25rem', '1.25rem', '1.25rem']}
                    mb={isCompact ? ['0.625rem', '0.625rem', '0.625rem'] : ['0.625rem', '0.625rem', '0.625rem']}
                    variant={isCompact ? 'small' : 'estateCardText'}
                  >
                    {roomCount ? `${roomCount} ${objectData?.roomNumber}` : ' '}
                    {roomCount && livingSpace ? ' • ' : null}
                    {livingSpace ? `${livingSpace} ${objectData?.livingSpace}` : null}
                  </Text>
                  <Flex alignItems="center">
                    <LocationIcon fill="darkGrey" />
                    <Text
                      itemProp="addressLocality"
                      ml={isCompact ? ['0.313rem', '0.313rem', '0.313rem'] : ['0.625rem', '0.625rem', '0.625rem']}
                      variant="small"
                    >
                      {town}
                    </Text>
                  </Flex>
                  <Divider my="14px" />
                  <Flex alignItems="center" justifyContent="space-between" mb={['0.75rem', '0.75rem', '0.75rem']}>
                    {isRentalEstate ? (
                      <Text variant={isCompact ? 'smallBold' : 'bodyBold'} my={isCompact ? [0, 0, 0] : ''}>
                        {rentalEstate.priceOnRequest
                          ? objectData?.priceOnRequest
                          : `${formatEuro(rentalEstate.rentCold)}${objectData?.rentalPrice}`}
                      </Text>
                    ) : (
                      <>
                        <Text
                          itemProp="price"
                          content={purchasePrice}
                          my={isCompact ? [0, 0, 0] : ''}
                          variant={isCompact ? 'smallBold' : 'bodyBold'}
                        >
                          {formatEuro(purchasePrice)}
                        </Text>
                        <span hidden itemProp="priceCurrency" content="EUR">
                          €
                        </span>
                      </>
                    )}
                    <Flex alignItems="center">
                      <Text
                        color="primary"
                        fontSize={['0.875rem', '0.875rem', '0.875rem']}
                        fontWeight={['600', '600', '600']}
                        mr={['0.625rem', '0.625rem', '0.625rem']}
                        variant="small"
                      >
                        <Link href={`/immobilien/immobiliensuche/expose/${id}`}>
                          <a title={`${title} in ${town}`} aria-labelledby={id}>
                            {t('action')}
                          </a>
                        </Link>
                      </Text>
                      <Flex pt="0.188rem">
                        <ArrowIcon heigth="1rem" style={{ transform: 'rotate(90deg)' }} width="0.5rem" />
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </a>
            </Link>
            {isRentalEstate ? null : (
              <Flex
                alignItems="center"
                bg="#F7F7F7"
                justifyContent="space-between"
                sx={{
                  border: '1px solid #EBEBEB',
                  borderRadius: '.25rem',
                  padding: '0.5rem 0.938rem 0.5rem 0.938rem',
                }}
              >
                <Text mb={['0', '0', '0']} variant="notificationText">
                  Finanzierung mtl. ca.
                </Text>
                <Text mb={['0', '0', '0']} variant="subheadSmallEstate">
                  {formatEuro(monthlyRate)}
                </Text>
                <FinancingTooltip
                  initialRepayment={formatDecimal(initialRepayment)}
                  boundInterestRate={formatDecimal(boundInterestRate)}
                  effektivZins={formatDecimal(effektivZins)}
                  purchasePrice={purchasePrice}
                  equityCapital={equityCapital}
                  incidentalPurchaseCosts={incidentalPurchaseCosts}
                  loanAmount={loanAmount}
                  monthlyRate={monthlyRate}
                  zinsBindungInJahren={zinsBindungInJahren}
                  produktAnbieter={produktAnbieter}
                  equityCapitalRatio={equityCapitalRatio}
                  incidentalPurchaseCostsRatio={incidentalPurchaseCostsRatio}
                  summeZahlungen={summeZahlungen}
                  laufzeitInMonaten={laufzeitInMonaten}
                />
              </Flex>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

Card.propTypes = {
  boundInterestRate: PropTypes.number.isRequired,
  children: PropTypes.node,
  deactivated: PropTypes.bool,
  deleted: PropTypes.bool,
  effectiveInterestRate: PropTypes.number.isRequired,
  equityCapital: PropTypes.number.isRequired,
  equityCapitalRatio: PropTypes.number.isRequired,
  fixedInterestRate: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired, // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  incidentalPurchaseCosts: PropTypes.number.isRequired,
  incidentalPurchaseCostsRatio: PropTypes.number.isRequired,
  initialRepayment: PropTypes.number.isRequired,
  isCompact: PropTypes.bool, // eslint-disable-next-line react/forbid-prop-types
  isDragging: PropTypes.object,
  isRentalEstate: PropTypes.bool.isRequired,
  livingSpace: PropTypes.number,
  loanAmount: PropTypes.number.isRequired,
  monthlyRate: PropTypes.number.isRequired,
  priceOnRequest: PropTypes.number.isRequired,
  produktAnbieter: PropTypes.string.isRequired,
  summeZahlungen: PropTypes.string.isRequired,
  laufzeitInMonaten: PropTypes.string.isRequired,
  purchasePrice: PropTypes.number,
  rentCold: PropTypes.number.isRequired,
  rentalEstate: PropTypes.shape({
    priceOnRequest: PropTypes.bool,
    rentCold: PropTypes.number,
  }),
  roomCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  town: PropTypes.string.isRequired,
  zinsBindungInJahren: PropTypes.number.isRequired,
  effektivZins: PropTypes.number.isRequired,
};

Card.defaultProps = {
  children: null,
  deactivated: false,
  deleted: false,
  rentalEstate: { priceOnRequest: undefined, rentCold: undefined },
  isDragging: null,
  isCompact: false,
  livingSpace: undefined,
  purchasePrice: undefined,
  roomCount: undefined,
};

export default Card;
export { Card as ImmoCard };
