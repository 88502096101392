import { useQuery } from 'react-query';

import { configApi } from '@/services';
import getHostname from '@/utils/getHostname';

const useInstitut = () =>
  useQuery('institut', async () => {
    const settings = await configApi('/settings', getHostname());

    return settings?.values.SPARKASSE_FULLNAME;
  });

export default useInstitut;
